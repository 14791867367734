import React from 'react';
import { motion } from 'framer-motion';

import './RotatingContainer.scss';

const RotatingContainer = () => {
    return (
        <>
            <motion.div
                className='rotating-block'
                animate={{
                    scale: [1, 2, 2, 1, 1],
                    rotate: [0, 0, 90, 90, 0],
                    borderRadius: ["20%", "20%", "70%", "20%", "20%"]
                }}
                transition={{duration: 0.5}}
            />
        </>
    );
}

export default RotatingContainer;