import './App.scss';
import React from 'react';
import { RedSlider, BlueFadingSlider, RotatingContainer } from './container';

function App() {
  return (
    <div className="App">
      <RedSlider />
      <BlueFadingSlider />
      <RotatingContainer />
    </div>
  );
}

export default App;
