import React from 'react';
import { motion, useMotionValue, useTransform } from 'framer-motion';

import './BlueFadingSlider.scss';

const BlueFadingSlider = () => {
    const x = useMotionValue(0)
    const opacity = useTransform(x, [-200, 0, 200], [0, 1, 0])

    return (
        <div>
            <h2>Fading slider</h2>
            <div className='bounds-blue-slider'>
                <motion.div
                    className="blue-slider-circle"
                    drag="x"
                    dragConstraints={{ left: -100, right: 100 }}
                    style={{ x, opacity }}
                    />
            </div>
        </div>
    )
}

export default BlueFadingSlider;