import React from 'react';
import { motion } from 'framer-motion';

import './RedSlider.scss';

const RedSlider = () => {
    return (
        <>
            <div>
                <h2>Slider inside bounds</h2>
                <div className="bounds">
                    <motion.div
                        className="red-slider-circle"
                        drag="x"
                        dragConstraints={{ left: -100, right: 100 }}
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                        />
                </div>
            </div>
        </>
    )
}

export default RedSlider;